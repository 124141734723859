export const booksGetters = {

    getBooksNames: (state) => {
        return state.books.map(book => {
            return {
                id: book.id,
                name: book.name
            };
        });
    },
    getBookChapters:(state) => (id) => {
        return state.books.find(book => book.id === id).chapters;
    }
}
