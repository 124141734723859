import {createApp} from 'vue'
import App from '@/App.vue'

// state manager
import {createPinia} from 'pinia'

// Router
import router from '@/router'

// Vuetify
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Styles
import '@/styles/index.css'

const vuetify = createVuetify({
    components,
    directives,
})

const pinia = createPinia()

createApp(App)
    .use(vuetify)
    .use(pinia)
    .use(router)
    .mount('#app')
