<template>
  <div class="h-screen w-screen bg-amber-100">
    <router-view></router-view>
  </div>

</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

