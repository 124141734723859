<template>
  <div class="p-3">
    <button @click="openAddBookPage()"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
      Add Book
    </button>
    <button @click="openAddBookDataPage()"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
      Add Data to book
    </button>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
      Trigger Flow
    </button>
  </div>
</template>

<script>

export default {
  name: "home-screen",
  methods: {
    openAddBookPage() {
      this.$router.push({name: 'add-book'})
    },
    openAddBookDataPage() {
      this.$router.push({name: 'add-data'})
    }
  }
}
</script>

<style scoped>
</style>
