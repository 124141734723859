<template>
  <div>
    <div v-for="chapter in chapters" :key="chapter.section">
      <div class="mt-2 flex items-center">
        <span>{{ `${getCurrentSectionHierarchy}${chapter.section}` }}</span>
        <input v-model="chapter.name" class="ml-2">
        <v-btn class="ms-2" density="compact" icon="mdi-plus" color="primary" @click="appendChapter(chapter)">
          +
        </v-btn>
        <v-btn class="ms-2" density="compact" icon="mdi-plus" color="primary" @click="removeChapter()">-</v-btn>
      </div>
      <NestedChapterViewerComponent class="ms-4"
                                    v-if="chapter.chapters && chapter.chapters.length"
                                    :chapters="chapter.chapters"
                                    :currentSectionHierarchy="getCurrentSectionHierarchy + chapter.section"
      />
    </div>
  </div>
</template>

<script>
import NestedChapterViewerComponent from './NestedChapterViewerComponent.vue'
import {mapActions} from 'pinia';
import {useBooksStore} from '@/stores/books';

export default {
  name: "nested-chapter-viewer",
  props: {
    chapters: {
      type: Array,
      required: true
    },
    currentSectionHierarchy: {
      type: String,
      required: false
    }
  },
  components: {
    NestedChapterViewerComponent
  },
  computed: {
    getCurrentSectionHierarchy() {
      return this.currentSectionHierarchy ? `${this.currentSectionHierarchy}.` : ''
    }
  },
  methods: {
    ...mapActions(useBooksStore, ['addChapter']),
    appendChapter(parentChapter) {
      this.addChapter(
          {
            name: 'New Chapter',
            section: parentChapter.chapters ? parentChapter.chapters.length + 1 : 1,
          },
          this.getCurrentSectionHierarchy + parentChapter.section
      )
    },
  }

}
</script>

<style scoped>

</style>