import BooksAPI from "@/http/booksAPI";
export const booksActions = {

    addChapter(chapter, parentSection = null) {
        if (parentSection) {
            let targetSection = parentSection.split('.')
            let currentLevel = this.newBook
            for (let i = 0; i < targetSection.length; i++) {
                currentLevel = currentLevel.chapters[targetSection[i] - 1]
                if (!currentLevel.chapters) {
                    currentLevel.chapters = [];
                }
            }
            currentLevel.chapters.push(chapter);
        } else {
            if (!this.newBook.chapters) {
                this.newBook.chapters = [];
            }
            this.newBook.chapters.push(chapter);
        }
    },

    fetchBooks() {
        BooksAPI.getBooks().then((response) => {
            this.books = response.data;
        });
    }
}