// books/books.js
import {defineStore} from 'pinia';
import {booksState} from './state';
import {booksGetters} from './getters';
import {booksActions} from './actions';

export const useBooksStore = defineStore('books', {
    state: () => ({...booksState()}),
    getters: ({...booksGetters}),
    actions: ({...booksActions}),
});
