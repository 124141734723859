import {createRouter, createWebHistory} from "vue-router";
import Home from "@/pages/Home.vue";
import AddBook from "@/pages/AddBook.vue";
import AddData from "@/pages/AddData.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', component: Home, name: 'home'},
        {path: '/book', component: AddBook, name: 'add-book'},
        {path: '/book-data', component: AddData, name: 'add-data'},
    ],
})

export default router