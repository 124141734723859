<template>
  <div>
    <div class="p-3 flex justify-center w-100 h-100">
      <div class="w-50">

        <v-text-field label="Book Name" v-model="newBook.name"></v-text-field>

        <v-text-field label="Book Authors" v-model="newBook.authors"></v-text-field>

        <v-text-field label="Number of Pages" v-model="newBook.pagesCount"></v-text-field>

        <v-text-field label="ISBN" v-model="newBook.isbn"></v-text-field>

        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2" @click="saveBook()">
          Save Book
        </button>
      </div>
      <div class="w-50 flex flex-col items-center">
        <span class="text-3xl">{{ newBook.name }}</span>
        <span class="text-2xl">{{ newBook.authors }}</span>
        <span class="text-2xl">{{ newBook.pagesCount }}</span>
        <span class="text-2xl">{{ newBook.isbn }}</span>

        <div v-if="hasChapters">
          <h1 class="text-lg font-bold">Chapters</h1>
          <NestedChapterViewerComponent :chapters="newBook.chapters"/>
        </div>
        <v-btn @click="addSampleChapter()"
               class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2">
          Add Chapter
        </v-btn>


      </div>
    </div>
  </div>

</template>


<script>
import NestedChapterViewerComponent from "@/components/NestedChapterViewerComponent.vue";
import {mapState, mapActions} from 'pinia';
import {useBooksStore} from "@/stores/books";
import bookAPI from "@/http/booksAPI";

export default {
  name: "add-book",
  components: {NestedChapterViewerComponent},
  data() {
    return {}
  },
  computed: {
    ...mapState(useBooksStore, ['newBook']),
    hasChapters() {
      return this.newBook.chapters && this.newBook.chapters.length > 0
    }
  },
  methods: {
    ...mapActions(useBooksStore, ['addChapter']),
    saveBook() {
      bookAPI.createBook(this.newBook).then(books => {
        console.log(books)
      }).catch(err => {
        console.log(err)
      });
    },
    addSampleChapter() {
      this.addChapter({
        section: this.newBook.chapters ? this.newBook.chapters.length + 1 : 1,
        name: 'Sample Chapter'
      })
    }
  }
}
</script>

<style scoped>

</style>