import BaseAPI from "@/http/baseAPI";

export default class BooksAPI extends BaseAPI {
    constructor(axios) {
        console.log('heh')
        super(axios, "books");
    }

    static getBooks() {
        return this.getInstance().getData("books/");
    }

    static getBook(isbn) {
        return this.getInstance().getData(`books/${isbn}`);
    }

    static createBook(data) {
        return this.getInstance().postData("books/", data);
    }
}